<template>
  <div>
    <!-- ========== HEADER ========== -->
    <header id="header">
      <b-navbar toggleable="lg" type="light" variant="white">
        <b-navbar-brand href="/">
          <img src="@/assets/images/logo/gytp.png" width="60" alt="logo">
          SuperStarT
        </b-navbar-brand>
        <b-navbar-nav>
          <b-nav-item href="/hosts">{{ $t('nav.home') }}</b-nav-item>
        </b-navbar-nav>
        <el-dropdown class="ml-auto" @command="handleLang">
          <span class="el-dropdown-link">
            <font-awesome-icon icon="globe" />
            {{ $t('lang') }}<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </b-navbar>
    </header>
    <router-view />
    <!-- ========== END HOSTS ========== -->
    <footer class="pt-3 bottom">
      <!--      <div class="text-center">-->
      <!--        <p>如果你有任何问题，通过以下方式联系我们</p>-->
      <!--        <b-row>-->
      <!--          <b-col>-->
      <!--            <b-img width="150px" :src="require('@/assets/images/QR/QR_wechat_official.png')" />-->
      <!--            <p class="text-secondary">-->
      <!--              <font-awesome-icon :icon="['fab', 'weixin']" /> Official-->
      <!--            </p>-->
      <!--          </b-col>-->
      <!--        </b-row>-->
      <!--      </div>-->
      <el-divider class="w-75 mx-auto" />
      <div class="text-center mb-3">©2021 弧米科技
        <a href="https://beian.miit.gov.cn/" class="text-secondary" target="_blank">
          浙ICP备17057855号-3
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Index',
  methods: {
    handleLang(lang) {
      this.$i18n.locale = lang
    }
  }
}
</script>

<style scoped>
.bottom {
  font-size: 0.6rem;
}
</style>
